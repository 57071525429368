import { LinkButton } from '@adsk/alloy-react-button';
import EmptyState from '@adsk/alloy-react-empty-state/es/EmptyState';
import { IllustrationType } from '@adsk/alloy-react-illustration';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import { ComponentProps, ReactNode, useEffect } from 'react';
import { OasisError } from '~/shared/utils/oasis-error';
import { FadingEllipsis } from './fading-ellipsis';

/**
 * All of these props are optional because we have a default error of "UNKNOWN".
 * By leaving them optional you can pass conditional codes, but also override specifics
 * to further describe the error to the user.
 */

type ErrorTypeKeys = keyof typeof errorTypes;
export type OasisErrorCodes = ErrorTypeKeys | 'ABORTED' | 'OFFLINE' | (string & {});

interface Props {
  oasisError?: OasisError | unknown;
  code?: OasisErrorCodes;
  illustration?: IllustrationType;
  title?: string;
  description?: ReactNode;
  children?: ReactNode;
  resetErrorBoundary?: () => void;
  additionalData?: Record<string, unknown>;
}

interface ErrorAttributes {
  illustration: IllustrationType;
  title: string;
  description?: ReactNode;
}

const errorTypes = {
  UNKNOWN: {
    illustration: 'buildingConstructionGrey',
    title: 'We are experiencing technical difficulties',
    description: 'Please try refreshing the page, or check back later.',
  },
  BAD_REQUEST: {
    illustration: 'desktopComputerGrey',
    title: 'Bad request',
    description: 'There was a problem with your request. Please try again.',
  },
  UNAUTHORIZED: {
    illustration: 'desktopComputerGrey',
    title: 'Your session is invalid or expired',
    description: 'Please log in and try again',
  },
  FORBIDDEN: {
    illustration: 'desktopComputerGrey',
    title: 'You do not have permission',
    description: 'Please check your permissions and try again.',
  },
  NOT_FOUND: {
    illustration: 'desktopComputerGrey',
    title: 'Not found',
    description: 'Please check the URL and try again.',
  },
  TIMEOUT: {
    illustration: 'desktopComputerGrey',
    title: 'Request timed out',
    description: 'If the problem persists please contact support.',
  },
  TOO_MANY_REQUESTS: {
    illustration: 'desktopComputerGrey',
    title: 'Too many requests',
    description: 'Wait a minute and try again. We are working to increase our capacity.',
  },
  OFFLINE: {
    illustration: 'skylineGrey',
    title: 'No Internet Connection',
    description:
      "It appears that you're currently not connected to the internet. Please check your internet connection and try again.",
  },
  RECONNECTING: {
    illustration: 'skylineGrey',
    title: 'Trying to reconnect...',
    description: (
      <div>
        <div>Please try restarting the app, or check back later.</div>
        <div>We appreciate your patience.</div>
      </div>
    ),
  },
} satisfies Record<string, ErrorAttributes>;

export function OasisErrorState({ children, additionalData, ...props }: Props) {
  const oasisError = props.oasisError instanceof OasisError ? props.oasisError : undefined;
  const code = props.code || oasisError?.code || 'UNKNOWN';

  useEffect(
    () => {
      if (
        [
          'BAD_REQUEST',
          'TOO_MANY_REQUESTS',
          'INTERNAL_SERVER_ERROR',
          'BAD_GATEWAY',
          'SERVICE_UNAVAILABLE',
          'GATEWAY_TIMEOUT',
          'UNKNOWN',
        ].includes(code)
      ) {
        const message = props.oasisError instanceof OasisError ? props.oasisError.message : '';

        Oasis.Logger.error({
          ...additionalData,
          oasisError: props.oasisError,
          msg: `<OasisErrorState /> Error code: ${code}. ${message}`,
        });
      }

      // Attempt to reload the console if we're not in web
      if ((props.code === 'UNKNOWN' || props.code === 'RECONNECTING') && !Oasis.Env.store.isWeb) {
        // setTimeout(() => window.location.reload(), 3000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (props.code === 'ABORTED') {
    return null;
  }

  const predefinedError = errorTypes[code as keyof typeof errorTypes] ?? errorTypes.UNKNOWN;
  const error: ErrorAttributes = { ...predefinedError };

  // Someone can pass in custom values to the component itself or get them from `oasisError`
  // but if they don't do either of those we'll just use the defaults error values from above.
  // Note: the types are a mess trying to loop on the keys and set the value so we go key by key.
  const illustration = props.illustration || oasisError?.illustration;
  if (illustration) error.illustration = illustration;

  const title = props.title || oasisError?.title;
  if (title) error.title = title;

  const description = props.description || oasisError?.description;
  if (description) error.description = description;

  return (
    <div className="flex-1 flex flex-col items-center justify-center h-full max-w-lg m-auto pb-10">
      {props.code === 'OFFLINE' && <OfflineIllo className="mb-5 w-36" />}

      {props.code === 'RECONNECTING' && <ProgressRing size="large" />}

      <EmptyState
        hideIllustration={props.code === 'OFFLINE' || props.code === 'RECONNECTING'}
        illustrationType={error.illustration}
        title={error.title}
        description={error.description}
      />

      {children && <div className="mt-5">{children}</div>}

      {(props.code === 'UNKNOWN' || oasisError?.code === 'UNKNOWN') && (
        <p className="font-medium text-charcoal-700 -mt-5">We appreciate your patience as we work to fix this.</p>
      )}

      {(props.code === 'UNKNOWN' || oasisError?.code === 'UNKNOWN') && (
        <div className="mt-6">
          <LinkButton onClick={() => window.location.reload()} className="text-blue-500 font-medium">
            Try Again
          </LinkButton>
        </div>
      )}

      {props.code === 'OFFLINE' && (
        <p className="mt-12 text-label-md">
          Waiting for connection
          <FadingEllipsis />
        </p>
      )}
    </div>
  );
}

function OfflineIllo(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 244 182" fill="none" {...props}>
      <path
        fill="#CDD9E2"
        stroke="#CDD9E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M184.15 38.967v140.416h-54.117V38.967L157.1.65l27.05 38.317Z"
      />
      <path
        fill="#CDD9E2"
        stroke="#CDD9E2"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M80.517 69.583a8.767 8.767 0 1 0 0-17.533 8.767 8.767 0 0 0 0 17.533Z"
      />
      <path
        fill="#CDD9E2"
        stroke="#CDD9E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M63.85 130.65h-5.9v10.817h5.9V130.65ZM78.3 130.65h-5.9v10.817h5.9V130.65ZM63.85 150.017h-5.9v10.816h5.9v-10.816Z"
      />
      <path
        fill="#B4C5D0"
        stroke="#B4C5D0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M45.433 95.767H1.167v65.6h44.266v-65.6Z"
      />
      <path
        fill="#B4C5D0"
        stroke="#B4C5D0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M213.383 96.9h-5.9v10.817h5.9V96.9ZM213.383 77.517h-5.9v10.816h5.9V77.517ZM213.383 116.267h-5.9v10.816h5.9v-10.816Z"
      />
      <path
        stroke="#1F323D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.74}
        d="M81.867 44.85V31.317M79.167 76.8v13.533M65.75 54.6l-12.883-4.183M95.3 67.05l12.867 4.183M70.033 72.95l-7.95 10.95M91 48.7l7.967-10.967M72.217 47.1l-7.95-10.95M88.817 74.533l7.95 10.967M64.9 64.467 52.033 68.65M96.133 57.167 109 52.983"
      />
      <path
        stroke="#1F323D"
        strokeMiterlimit={10}
        strokeWidth={0.75}
        d="M144.517 45.267H139.6v10.816h4.917V45.267ZM139.617 78.417h4.916V67.6h-4.916v10.817ZM144.517 89.917H139.6v10.816h4.917V89.917ZM198.567 181.35v-115h44.266v115M38.217 181.35v-61.883h98.366v52.05"
      />
    </svg>
  );
}
