export const consoleLogoAlphaUrl = '/img/console-logos/console_logo_alpha.png';
export const consoleLogoBetaUrl = '/img/console-logos/console_logo_beta.png';
export const consoleLogoDevUrl = '/img/console-logos/console_logo_dev.png';
export const consoleLogoDevStgUrl = '/img/console-logos/console_logo_devstg.png'; // need new value
export const consoleLogoProdUrl = '/img/console-logos/console_logo_prod.png';
export const desktopDeviceIlloUrl = '/illustrations/desktop-device.svg';
export const emptyWorkshopTableIlloUrl = '/illustrations/empty-workshop-table.svg';
export const fluidErrorVRUrl = '/img/FluidErrorVR.svg';
export const fullLogoAlphaUrl = '/img/full-logos/adskwxr_logo_alpha.png';
export const fullLogoBetaUrl = '/img/full-logos/adskwxr_logo_beta.png';
export const fullLogoDevUrl = '/img/full-logos/adskwxr_logo_dev.png';
export const fullLogoDevStgUrl = '/img/full-logos/adskwxr_logo_devstg.png'; // need new value
export const fullLogoProdUrl = '/img/full-logos/adskwxr_logo_prod.png';
export const iconXUrl = '/icons/x.svg';
export const landingUrl = '/img/landing.jpg';
export const logomarkUrl = '/img/logomark.svg';
export const logoUrl = '/img/logo.svg';
export const pairedDeviceIlloUrl = '/illustrations/paired-device.svg';
export const webLogoAlphaUrl = '/img/web-logos/web_logo_alpha.png';
export const webLogoBetaUrl = '/img/web-logos/web_logo_beta.png';
export const webLogoDevUrl = '/img/web-logos/web_logo_dev.png';
export const webLogoDevStgUrl = '/img/web-logos/web_logo_devstg.png'; // need new value
export const webLogoProdUrl = '/img/web-logos/web_logo_prod.png';
export const workshopEnv1BlurUrl = '/img/env-1-blur.png';
export const workshopEnv1Url = '/img/env-1.jpg';
export const workshopEnv2Url = '/img/env-2.jpg';
export const workshopEnv3Url = '/img/env-3.jpg';
export const workshopOnboardingUrl = '/img/WorkshopOnboarding.svg';
export const workshopScene = '/img/workshop-scene-2.jpg';
export const workshopXRLogomarkUrl = '/img/WorkshopXRLogomark.svg';
export const workshopXRLogoUrl = '/img/WorkshopXRLogo.svg';
export const workshopXRProductIconUrl = '/img/WorkingXRProductIcon.svg';

// Temp files
export const tempThumbnail = '/temp/thumb.jpg';
