import { LinkButton } from '@adsk/alloy-react-button';
import { useEffect } from 'react';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { landingUrl } from '../../utils/const.asset-urls';
import { ClientLicenses } from './components/client-licenses';
import { CloudLicenses } from './components/cloud-licenses';
import { HeadsetTracking } from './components/headset-tracking';

enum AboutType {
  Mobile,
  Cloud,
}

const links = [
  { label: 'Home', href: '/', content: null },
  {
    label: 'Meta Quest App',
    href: '#meta-quest-app',
    content: (
      <AboutSubsection headingLevel={2} type={AboutType.Mobile}>
        <ClientLicenses headingLevel={3} />
      </AboutSubsection>
    ),
  },
  {
    label: 'Cloud Components',
    href: '#cloud-components',
    content: (
      <AboutSubsection headingLevel={2} type={AboutType.Cloud}>
        <CloudLicenses headingLevel={3} />
      </AboutSubsection>
    ),
  },
  {
    label: 'Headset Data Collection Notice',
    href: '#headset-data-collection',
    content: <HeadsetTracking />,
  },
];

export default function AboutPage() {
  useEffect(() => {
    const el = document.getElementById(window.location.hash.replace('#', ''));
    if (el) scrollTo(0, el.offsetTop);
  }, []);

  return (
    <div className="p-10 md:p-20 leading-normal">
      <div
        className="fixed inset-0 flex items-center justify-center bg-cover"
        style={{ backgroundImage: `url(${landingUrl})` }}
      />

      <main className="w-full px-10 pb-10 pt-5 md:px-16 md:pb-16 bg-white shadow-high rounded-md relative z-10 prose">
        <nav className="sticky top-0 bg-white py-3 w-full border-b border-charcoal-100 space-x-10">
          {links.map(link => (
            <LinkButton key={link.href} as="a" href={link.href} className="text-blue-500 font-medium">
              {link.label}
            </LinkButton>
          ))}
        </nav>

        {links.map(link => {
          if (!link.content) {
            return null;
          }

          return (
            <section key={link.href}>
              <h1 id={link.href.replace('#', '')} className="pt-16">
                {link.label}
              </h1>

              {link.content}
            </section>
          );
        })}
      </main>
    </div>
  );
}

interface AboutSubsectionProps {
  headingLevel: number;
  type: AboutType;
  dataCollection?: boolean;
  children?: React.ReactNode;
}

interface TermsProps {
  type: AboutType;
}

function Terms(props: TermsProps) {
  if (props.type == AboutType.Mobile) {
    return (
      <p>
        All use of this Software is subject to the end-user license agreement contained within the terms and conditions
        applicable to the app store from which you downloaded this mobile app, or the end-user license agreement
        included in this app&apos;s webpage, in such app store, or accepted upon installation of this app.
      </p>
    );
  } else if (props.type == AboutType.Cloud) {
    return (
      <p>
        All use of this Software is subject to the Autodesk terms of service accepted upon access or use of this Service
        or made available on the Autodesk webpage. Autodesk terms of service for Autodesk&apos;s various web services
        can be found{' '}
        <LinkButton
          as="a"
          href="https://www.autodesk.com/company/legal-notices-trademarks/terms-of-service-autodesk360-web-services"
          className="text-blue-500 font-medium"
        >
          here
        </LinkButton>
        .
      </p>
    );
  } else {
    return null; // TODO implement AboutType.Desktop
  }
}

function AboutSubsection(props: AboutSubsectionProps) {
  const Heading = `h${props.headingLevel}` as keyof JSX.IntrinsicElements;
  const ProductOrService = `${props.type == AboutType.Mobile ? 'Product or ' : ''}Service`;
  const branding = useTemporaryBranding();

  return (
    <>
      <p>
        <strong>{branding.productName + ' © 2024 Autodesk, Inc. All rights reserved.'}</strong>
      </p>

      <strong>
        <Terms type={props.type} />
      </strong>

      <Heading>Privacy</Heading>

      <p>
        To learn more about Autodesk&apos;s online and offline privacy practices, please see the{' '}
        <LinkButton
          as="a"
          href="http://www.autodesk.com/company/legal-notices-trademarks/privacy-statement"
          className="text-blue-500 font-medium"
        >
          Autodesk Privacy Statement
        </LinkButton>
        .{props.dataCollection && <strong> PLEASE SEE DATA COLLECTION NOTICE AT THE END OF THIS ABOUT SECTION</strong>}
      </p>

      <p>All other brand names, product names or trademarks belong to their respective holders.</p>

      <Heading>Autodesk Trademarks</Heading>

      <p>
        The trademarks on the{' '}
        <LinkButton
          as="a"
          href="https://www.autodesk.com/company/legal-notices-trademarks/intellectual-property/trademarks"
          className="text-blue-500 font-medium"
        >
          Autodesk Trademarks page
        </LinkButton>{' '}
        are registered trademarks or trademarks of Autodesk, Inc., and/or its subsidiaries and/or affiliates in the USA
        and/or other countries.
      </p>

      <Heading>Patents</Heading>

      <p>
        This {ProductOrService} is protected by patents listed on the{' '}
        <LinkButton
          as="a"
          href="https://www.autodesk.com/company/legal-notices-trademarks/patents"
          className="text-blue-500 font-medium"
        >
          Autodesk Patents
        </LinkButton>{' '}
        page.
      </p>

      <Heading>Autodesk Cloud and Desktop Components</Heading>

      <p>
        This {ProductOrService} may incorporate or use background Autodesk online and desktop technology components. For
        information about these components, see{' '}
        <LinkButton
          as="a"
          href="https://www.autodesk.com/company/legal-notices-trademarks/autodesk-cloud-platform-components"
          className="text-blue-500 font-medium"
        >
          Autodesk Cloud Platform Components
        </LinkButton>{' '}
        and{' '}
        <LinkButton
          as="a"
          href="https://www.autodesk.com/company/legal-notices-trademarks/autodesk-desktop-platform-components"
          className="text-blue-500 font-medium"
        >
          Autodesk Desktop Platform Components
        </LinkButton>
        .
      </p>

      <Heading>Third-Party Trademarks, Software Credits and Attributions</Heading>

      {props.children}

      {props.dataCollection && (
        <>
          <Heading>Data Collection Notice</Heading>

          <p>TODO</p>
        </>
      )}
    </>
  );
}
