import { assert } from '@fluidframework/core-utils';
import { Signaler, SignalListener } from '@fluid-experimental/data-objects';
import { ContainerSchema, IFluidContainer } from '@fluidframework/fluid-static';
import { Jsonable } from '@fluidframework/datastore-definitions';

import {
  AWSClient,
  ContainerServices,
  DefaultTokenProvider,
  IRouterliciousAudience,
  PermissionContext,
} from '@concurrent-experience/routerlicious-client';

import { ITelemetryBaseLogger } from '@fluidframework/core-interfaces';
import { SharedPropertyTree } from '@fluid-experimental/property-dds';
import { getUserColor } from '../utils/userColor';
import { getEnvironmentEndpoints } from './env';
import { EventEmitter } from 'events';

export interface User {
  id?: string;
  name?: string;
  userId: string;
  userName: string;
  additionalDetails: Record<string, unknown>;
}
type ContainerLoadReturnType = [IFluidContainer, string, ContainerServices];

/**
 * The `ConnectionManager` class serves as a wrapper around the Fluid Framework client, facilitating the management of connections to Fluid containers.
 * It offers a simplified API for connecting to a Fluid container, handling connection states, and interacting with the Fluid container.
 *
 * The `ConnectionManager` extends `EventEmitter` and emits the following events:
 * - 'connected': Emitted when a connection to the Fluid container is successfully established.
 * - 'disconnected': Emitted when the connection to the Fluid container is lost.
 */
export class ConnectionManager extends EventEmitter {
  readonly ID: string = 'fluid';

  readonly containerSchema: ContainerSchema = {
    initialObjects: {
      propertyTree: SharedPropertyTree,
      signaler: Signaler,
    },
  };

  isConnected = false;

  public documentId: string = '';

  private _audience?: IRouterliciousAudience;

  private _signaler?: Signaler;

  private _propertyTree?: SharedPropertyTree;

  private tokenProvider: DefaultTokenProvider;

  private _user?: User;

  public client: AWSClient;

  private container: IFluidContainer | undefined = undefined;

  private _isClosed: boolean = false;
  public get isClosed(): boolean {
    return this._isClosed;
  }

  constructor(
    private readonly getAccessToken: () => Promise<string>,
    private readonly getUser: () => User | undefined = () => undefined,
    public readonly env: 'staging' | 'production' = 'staging',
    private readonly fluidLogger: ITelemetryBaseLogger = {
      send: event => {
        // default logger we only log errors
        if (event.category === 'error') {
          console.error(event);
        }
      },
    }
  ) {
    super();
    const fluidEndpoints = getEnvironmentEndpoints(env);
    this.tokenProvider = new DefaultTokenProvider({
      threeLeggedTokenCallback: getAccessToken,
      env,
      userData: this.user,
    });

    this.client = new AWSClient({
      connection: {
        tenantId: 'fluid',
        orderer: fluidEndpoints.orderer,
        storage: fluidEndpoints.storage,
        tokenProvider: this.tokenProvider,
      },
      logger: this.fluidLogger,
    });
  }

  public clone(): ConnectionManager {
    return new ConnectionManager(this.getAccessToken, this.getUser, this.env, this.fluidLogger);
  }

  get user(): User {
    if (!this._user) {
      const user = this.getUser();
      if (user) {
        this._user = user;
      } else {
        this._user = {
          userId: '',
          userName: 'anonymous',
          additionalDetails: {}, // userAttributes
        };
      }

      const hexColor = getUserColor(this._user.userId);
      this._user.additionalDetails['hexColor'] = `#${hexColor}`;
      this._user.additionalDetails['color'] = parseInt(hexColor, 16);

      // TODO: address to MSFT - a discrepancy between `IUser` and `IMember`.
      // Both are required when a user is connected:
      // `IUser` is defined in "@fluidframework/protocol-definitions" having `id` and `name`, used internally.
      // `IMember` is defined in "@fluidframework/fluid-static" having `userId` and `userName`, available in the public API and used by `audience`.
      this._user.id = this._user.userId;
      this._user.name = this._user.userName;
    }
    return this._user;
  }

  private async createNewContainer(): Promise<ContainerLoadReturnType> {
    const { container, services } = await this.client.createContainer(this.containerSchema);
    const id = await container.attach();
    return [container, id, services];
  }

  private async loadExistingContainer(id: string): Promise<ContainerLoadReturnType> {
    const { container, services } = await this.client.getContainer(id, this.containerSchema);
    return [container, id, services];
  }

  public getMyself(): User {
    return this.user;
  }

  public async connect(documentId: string): Promise<string> {
    if (documentId) {
      this.documentId = documentId;
    } else {
      throw new Error('[Fluid.connect] documentId is required');
    }
    if (!this.isConnected) {
      const result = await this.loadExistingContainer(this.documentId);
      return this.postConnect(result);
    } else {
      return this.documentId || documentId;
    }
  }

  public async create(permissionContext: PermissionContext): Promise<string> {
    this.tokenProvider.permissionContext = permissionContext;
    const result = await this.createNewContainer();

    if (!permissionContext) {
      // This should never happen, but just in case.
      throw new Error('[Fluid], Permission context is required');
    }

    return this.postConnect(result);
  }

  private async postConnect(result: ContainerLoadReturnType): Promise<string> {
    const [container, ID, services] = result;

    if (this._audience) {
      const eventNames = (this._audience as any).eventNames();
      for (let i = 0; i < eventNames.length; i += 1) {
        const listeners = (this._audience as any).listeners(eventNames[i]);
        for (let j = 0; j < listeners.length; j += 1) {
          // eslint-disable-next-line no-unused-expressions
          this._audience?.off(eventNames[i], listeners[j]);
        }
      }
    }

    container.on('connected', () => this.onConnected());
    container.on('disconnected', () => this.onDisconnected());

    this.container = container;
    this._audience = services.audience;
    this._signaler = container.initialObjects['signaler'] as Signaler;
    this._propertyTree = container.initialObjects['propertyTree'] as SharedPropertyTree;
    (globalThis as any).NOP_PTREE = this._propertyTree as SharedPropertyTree;
    this.documentId = ID;
    this.isConnected = true;

    return this.documentId;
  }

  async disconnect(): Promise<void> {
    if (this.isConnected) {
      this.container?.dispose();
      this.isConnected = false;
      this._isClosed = true;
    }
  }

  private onDisconnected() {
    console.info(`[Fluid] Disconnected from ${this.documentId}!`);
    this.isConnected = false;
    setTimeout(() => {
      if (!this.isConnected) {
        this.emit('disconnected');
      }
    }, 500);
  }

  private onConnected() {
    console.info(`[Fluid] Connected to ${this.documentId}!`);
    this.isConnected = true;
    this.emit('connected');
  }

  get audience(): IRouterliciousAudience {
    assert(this._audience !== undefined, 'expected audience');
    return this._audience;
  }

  private get signaler(): Signaler {
    assert(this._signaler !== undefined, 'expected signaler');
    return this._signaler;
  }

  get propertyTree(): SharedPropertyTree {
    assert(this._propertyTree !== undefined, 'expected PropertyTree');
    return this._propertyTree;
  }

  public submitSignal<T>(signalName: string, payload?: Jsonable<T>): void {
    this.signaler.submitSignal(signalName, payload);
  }

  public onSignal(signalName: string, listener: SignalListener): void {
    this.signaler.onSignal(signalName, listener);
  }

  public offSignal(signalName: string, listener: SignalListener): void {
    this.signaler.offSignal(signalName, listener);
  }
}
