import { cloneDeep } from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { Err, Oasis, Ok } from '../..';
import { HttpUtils } from '../../lib/utils.http';
import { OasisHttp } from '../../providers/http/oasis-http.provider';
import { TokenManager } from '../token-manager/token-manager.service';
import { SessionSchemas } from './session.schemas';
import type { ActiveWorkshop, LoggedIn, LoggedOut, SessionResponses, ToolNames } from './session.types';

type Store = LoggedIn | LoggedOut;

const INITIAL_STATE: Store = {
  status: 'PENDING',
  missingPrerequisites: [],
  user: null,
  activeWorkshop: null,
  activeVrTool: 'none',
  hasActiveVrTool: false,
  favoriteProjectIds: [],
  license: {
    type: 'NO_ENTITLEMENT',
    trialEligible: true,
  },
};

const _parse = HttpUtils.createScopedParser('Session', SessionSchemas);

/**
 * # Session Service
 *
 * The session service is responsible for managing the user's session. It handle's auth state as well
 * as user specific data such as favorite projectss and active workshops.
 *
 * ## Licensing
 *
 * We have a few different licensing states that we need to be aware of:
 * - `PAID`: The user has a paid license.
 * - `TRIAL`: The user has an unrestricted trial license.
 * - `FREE_VIEWER`: The user has previous had a trial/paid license but it has expired. Features are limited.
 * - `NO_ENTITLEMENT`: The user has never had a trial/paid license. No features are available.
 *
 * ## Prerequisites
 *
 * We require users to have some basic prerequisites before they can use Workshop XR.
 * They must have a hub, a project and have agreed to the terms of service (have a profile).
 *
 * When any of these prerequisites are missing there is a full screen takeover that walks
 * the user through the process of setting up these prerequisites.
 *
 */
export const Session = {
  store: proxy<Store>(cloneDeep(INITIAL_STATE)),

  useStore() {
    return useSnapshot(Session.store);
  },

  resetStore() {
    Oasis.Logger.debug({ msg: '[Session.resetStore]' });

    Session.store.status = 'UNAUTHENTICATED';
    Session.store.user = null;
  },

  /**
   * @name init
   * Initialize the session. This will attempt to authenticate the user and connect to Mqtt/Fluid.
   */
  async init(params?: { connect?: boolean }) {
    let me = await Session.me();

    // If we ran into anything other than a 401 we should retry, but only after a delay.
    if (!me.ok && me.error.code !== 'UNAUTHORIZED') {
      await new Promise(resolve => setTimeout(resolve, 5000));
      me = await Session.me();
    }

    if (!me.ok) {
      await Session.logout({ skipAutodeskRedirect: true });
    } else {
      Session.setCurrentUser(me.value);
      await Session.identify(me.value);
      await Session.prepareLicense(me.value.license);
      await Session.preparePrerequisites(me.value);
      Session.setStatus('AUTHENTICATED');

      if (params?.connect) {
        await Oasis.Mqtt.connect();
        await Oasis.Fluid.init();
      }
    }

    return me;
  },

  /**
   * @name me
   * Get the current user.
   */
  async me() {
    try {
      if (!Oasis.TokenManager.getForgeXrAccessToken()) {
        return Err({ code: 'UNAUTHORIZED' });
      }

      const data = _parse('me', await OasisHttp.get('v1/users/@me').json());
      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Session.me]');
    }
  },

  /**
   * @name loginWithRedirect
   * Generate a Autodesk login url.
   */
  loginWithRedirect(params: {
    destination: 'login' | 'pairing-code' | 'trial';
    redirectUri?: string;
    successPath?: string;
  }) {
    const redirectUri = params.redirectUri || `${window.location.origin}/oauth/${params.destination}`;

    if (params.successPath) {
      Oasis.Storage.set('postAuthRedirect', params.successPath);
    }

    const url = `${Oasis.Env.store.apiUrl}/v1/device-pairing/authorize/web?redirect_uri=${redirectUri}`;

    if (Oasis.Bridge) {
      Oasis.Bridge.openUrl(url);
    } else {
      window.open(url, '_self');
    }
  },

  /**
   * @name handleOauthRedirect
   * Take the given tokens and send them to the server to create a Forge XR Session. Store the
   * access token and forge xr tokens.
   */
  async handleOauthSearchParams() {
    const params = new URLSearchParams(location.search);
    const preauthorized = params.get('preauthorized');
    const accessToken = params.get('access_token');
    const refreshToken = params.get('refresh_token');

    // When a user already has a session and get sent to the /trials page we just redirect them here
    // to finish the flow. We don't have a `refreshToken` if we've already created a session and got
    // a `forgeXrToken` back so instead we set `?preauthorized=true` to bypass that requirement.
    if (preauthorized) {
      return Session.init({ connect: true });
    }

    if (!accessToken || !refreshToken) {
      Oasis.Logger.error({
        error: params.get('err'),
        msg: '[Session.handleOauthSearchParams] Search params do not have required tokens.',
      });

      await Session.logout({ skipAutodeskRedirect: true });
      return Err({ code: 'NO_AUTH_TOKENS' });
    }

    const result = await TokenManager.createForgeXrTokens({ accessToken, refreshToken });

    if (!result.ok) {
      Oasis.Logger.error({ result, msg: '[Session.handleOauthSearchParams] Failed to create Forge XR token.' });
      return Err({ code: 'TOKEN_CREATION_FAILURE' });
    }

    return Session.init({ connect: true });
  },

  /**
   * @name getPairingCode
   * Ask the server for a 6 digit/character pairing code while supplying it
   * with the auth tokens for VR to eventually pair with.
   */
  async getPairingCode() {
    try {
      const params = new URLSearchParams(location.search);
      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');

      if (accessToken && refreshToken) {
        const result = await Oasis.TokenManager.createForgeXrTokens({ accessToken, refreshToken });

        if (!result.ok) {
          Oasis.Logger.error({
            result,
            msg: '[Session.getPairingCode] Failed to create Forge XR tokens.',
          });
          return Err({ code: 'TOKEN_CREATION_FAILURE' });
        }
      }

      const forgeXrToken = Oasis.TokenManager.getForgeXrAccessToken();

      if (!forgeXrToken) {
        return Err({ code: 'MISSING_FORGE_XR_TOKEN' });
      }

      const res = await OasisHttp.post('v2/device-pairing/devices/BETA_DEVICE/code', {
        headers: forgeXrToken ? { Authorization: `Bearer ${forgeXrToken}` } : undefined,
        body: JSON.stringify({}),
      }).json();

      const data = _parse('getPairingCode', res);

      Oasis.Segment.track('Pairing Code Generated');

      return Ok(data.code);
    } catch (error) {
      return HttpUtils.handleError(error, '[Session.getPairingCode]');
    }
  },

  /**
   * @name logout
   * Log the user out. Remove all tokens and reset the store.
   */
  async logout(params?: { skipAutodeskRedirect?: boolean }) {
    // In VR, we need to emit a signed out command so that the VR client
    // knows to switch to the pairing code screen.
    if (Oasis.Env.store.isVr) {
      await Oasis.NetworkCommands.emitSignedOut();
    }

    Session.resetStore();
    TokenManager.destroyTokens();
    Oasis.Storage.reset();

    if (Oasis.Env.store.isWeb && !params?.skipAutodeskRedirect) {
      return Session.redirectToAutodeskLogout();
    }

    Session.setStatus('UNAUTHENTICATED');
  },

  /**
   * @name deleteAllForgeXrSessions
   * Delete all the current user's Forge XR sessions.
   */
  async deleteAllForgeXrSessions() {
    try {
      const session = await OasisHttp.delete('v1/users/@me/sessions', {
        body: JSON.stringify({}),
      }).json();

      return Ok(session);
    } catch (error) {
      return HttpUtils.handleError(error, '[Session.deleteAllForgeXrSessions]');
    }
  },

  /**
   * @name redirectToAutodeskLogout
   * Redirect the user to the Autodesk logout page. Only works in web.
   */
  redirectToAutodeskLogout() {
    if (!Oasis.Env.store.isWeb) {
      return;
    }

    let redirect = 'https://workshop.autodesk.com/login';

    switch (Oasis.Env.store.releaseChannel) {
      case 'develop':
        redirect = 'https://dev.oasis.autodesk.com/login';
        break;
      case 'devstg':
        redirect = 'https://devstg.workshop.autodesk.com/login';
        break;
      case 'alpha':
        redirect = 'https://alpha.oasis.autodesk.com/login';
        break;
      case 'beta':
        redirect = 'https://beta.workshop.autodesk.com/login';
        break;
    }

    if (!redirect || window.location.host.includes('local')) {
      redirect = `${location.origin}/login`;
    }

    const subdomain = Oasis.Env.store.releaseChannel === 'alpha' ? 'accounts-staging' : 'accounts';
    window.location.href = `https://${subdomain}.autodesk.com/authentication/logout?returntourl=${redirect}`;
  },

  /**
   * @name identify
   * Identify the user in third party services.
   * Many services automatically populate additional fields based on the `Session.store` so
   * it may be best to make sure that `Session.setCurrentUser` has been called before this.
   */
  async identify(user: { analyticsId: string; firstName: string; lastName: string }) {
    // Its important to identify the user with the feature flag service before we start,
    // as feature flags rely on user identification to determine the app experience.
    // that's why it need to be awaited before proceeding with the rest of the services.
    await Oasis.FeatureFlags.identify({
      kind: 'user',
      key: user.analyticsId,
    });

    Oasis.Sentry.identify(user.analyticsId);

    Oasis.Segment.identify(user.analyticsId, {
      firstName: user.firstName,
      lastName: user.lastName,
    });

    Oasis.Pendo.identify({
      visitor: { id: user.analyticsId },
    });
  },

  async preparePrerequisites(user: SessionResponses['me']) {
    if (user.profile?.id) {
      Session.removeMissingPrerequisite('profile');
    } else {
      Session.addMissingPrerequisite('profile');
    }

    const projects = await Oasis.Projects.listProjects();
    const hasProject = projects.ok && projects.value.results.length;

    if (hasProject) {
      Oasis.Session.removeMissingPrerequisite('hub');
      Oasis.Session.removeMissingPrerequisite('project');
    } else {
      Oasis.Session.addMissingPrerequisite('project');

      const hubs = await Oasis.Hubs.list();
      const hasHub = hubs.ok && hubs.value.length;

      if (hasHub) {
        Oasis.Session.removeMissingPrerequisite('hub');
      } else {
        Oasis.Session.addMissingPrerequisite('hub');
      }
    }
  },

  /**
   * @name addMissingPrerequisite
   */
  addMissingPrerequisite(prerequisite: Store['missingPrerequisites'][0]) {
    if (!Session.store.missingPrerequisites.includes(prerequisite)) {
      Oasis.Logger.debug({ prerequisite, msg: '[Session.addMissingPrerequisite]' });
      Session.store.missingPrerequisites = [...Session.store.missingPrerequisites, prerequisite];
    }
  },

  /**
   * @name removeMissingPrerequisite
   */
  removeMissingPrerequisite(prerequisite: Store['missingPrerequisites'][0]) {
    if (Session.store.missingPrerequisites.includes(prerequisite)) {
      Oasis.Logger.debug({ prerequisite, msg: '[Session.removeMissingPrerequisite]' });
      Session.store.missingPrerequisites = Session.store.missingPrerequisites.filter(p => p !== prerequisite);
    }
  },

  setCurrentUser(user: SessionResponses['me']) {
    Oasis.Logger.debug({ user, msg: '[Session.setCurrentUser]' });

    Oasis.Storage.set('currentUserId', user.id);

    Session.store.user = {
      id: user.id,
      analyticsId: user.analyticsId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      country: user.countryCode,
      images: user.images,
      profile: user.profile?.settings,
    };
    Session.store.favoriteProjectIds = user.profile?.favoriteProjectIds ?? [];
  },

  setStatus(status: Store['status']) {
    Oasis.Logger.debug({ status, msg: '[Session.setStatus]' });

    if (status === 'UNAUTHENTICATED' && Oasis.Env.store.isVr) {
      Oasis.NetworkCommands.emitSignedOut();
    }

    Session.store.status = status;
  },

  async prepareLicense(license: SessionResponses['me']['license']) {
    // We dont have licenses in beta, devstg or develop
    if (Oasis.Env.store.releaseChannel === 'beta' || Oasis.Env.store.releaseChannel === 'devstg' || Oasis.Env.store.releaseChannel === 'develop') {
      Session.setLicense({ type: 'PAID', trialEligible: false });
      return;
    }

    if (!license) {
      Session.setLicense({ type: 'NO_ENTITLEMENT', trialEligible: true });
      return;
    }

    const validity = license.valid ? 'VALID' : 'INVALID';
    const trialStatus = license.isTrial ? 'TRIAL' : 'COMMERCIAL';

    // If they have a paid license we can forego the trial eligibility request
    if (`${validity} ${trialStatus}` === 'VALID COMMERCIAL') {
      Session.setLicense({ type: 'PAID', trialEligible: false });
      return;
    }

    const trialEligible = await Oasis.Users.verifyTrialEligibility();

    switch (`${validity} ${trialStatus}`) {
      case 'INVALID COMMERCIAL':
      case 'INVALID TRIAL':
        Session.setLicense({ type: 'FREE_VIEWER', trialEligible });
        break;

      case 'VALID TRIAL':
        const daysRemaining = license.expireAt
          ? Math.ceil((license.expireAt * 1000 - new Date().getTime()) / 24 / 60 / 60 / 1000)
          : 0;

        if (daysRemaining > 0) {
          Session.setLicense({ type: 'TRIAL', trialEligible: false, daysRemaining });
        } else {
          Session.setLicense({ type: 'FREE_VIEWER', trialEligible });
        }
        break;

      default:
        Session.setLicense({ type: 'NO_ENTITLEMENT', trialEligible });
    }
  },

  /**
   * @name setLicense
   * Set the license state.
   */
  setLicense(license: Store['license']) {
    Oasis.Logger.debug({ license, msg: '[Session.setLicense]' });
    Session.store.license = license;
  },

  /**
   * @name setActiveWorkshop
   * Set the active workshop.
   */
  async setActiveWorkshop(workshopId: string) {
    const [permission, workshop] = await Promise.all([
      Oasis.Workshops.getCurrentUsersPermission(workshopId),
      Oasis.Workshops.findWorkshopById(workshopId),
    ]);

    if (permission.ok && workshop.ok) {
      const activeWorkshop: ActiveWorkshop = {
        id: workshopId,
        name: workshop.value.name,
        projectId: workshop.value.projectId,
        permission: permission.value,
      };

      Session.store.activeWorkshop = Oasis.Debug.store.activeWorkshop || activeWorkshop;
    }
  },

  /**
   * @name clearActiveWorkshop
   * Clear the active workshop.
   */
  clearActiveWorkshop() {
    Session.store.activeWorkshop = Oasis.Debug.store.activeWorkshop || null;
  },

  /**
   * @name setActiveVrTool
   * Set the active VR tool.
   */
  setActiveVrTool(tool: ToolNames) {
    Session.store.activeVrTool = tool;
    Session.store.hasActiveVrTool = tool !== 'none' && Oasis.Env.store.isVr;
  },
};
