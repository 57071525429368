import { createApp } from '@oasis/app';
import { initOasisApp, releaseChannelSchema } from '@oasis/sdk';
import ReactDOM from 'react-dom/client';
import { z } from 'zod';

const envSchema = z.object({
  HTTP_URL: z.string(),
  MQTT_URL: z.string(),
  FORGE_LMV_ENV: z.string(),
  FLUID_ENV_STR: z.string(),
  ACM_NAMESPACE: z.string(),
  SEGMENT_WRITE_KEY: z.string(),
  SENTRY_DSN: z.string(),
  GIT_SHA: z.string(),
  RELEASE_CHANNEL: releaseChannelSchema,
  REACT_APP_ACS_ENV: z.string(),
  REACT_APP_ACS_REGION: z.string(),
});

(async () => {
  let env = {
    HTTP_URL: process.env.PUBLIC_HTTP_URL,
    MQTT_URL: process.env.PUBLIC_MQTT_URL,
    FORGE_LMV_ENV: process.env.PUBLIC_FORGE_LMV_ENV,
    FLUID_ENV_STR: process.env.PUBLIC_FLUID_ENV_STR,
    ACM_NAMESPACE: process.env.PUBLIC_ACM_NAMESPACE,
    SEGMENT_WRITE_KEY: process.env.PUBLIC_SEGMENT_WRITE_KEY || '',
    SENTRY_DSN: process.env.PUBLIC_SENTRY_DSN || '',
    RELEASE_CHANNEL: process.env.PUBLIC_RELEASE_CHANNEL || 'develop',
    GIT_SHA: 'local',
    REACT_APP_ACS_ENV: process.env.REACT_APP_ACS_ENV || 'PRODUCTION',
    REACT_APP_ACS_REGION: process.env.REACT_APP_ACS_REGION || 'US',
  };

  if (import.meta.env.MODE === 'production' && !location.host.includes('local')) {
    try {
      const envJson = await fetch('/environment.json').then(res => res.json());

      env = envSchema.parse({
        HTTP_URL: envJson.PUBLIC_HTTP_URL,
        MQTT_URL: envJson.PUBLIC_MQTT_URL,
        FORGE_LMV_ENV: envJson.PUBLIC_FORGE_LMV_ENV,
        FLUID_ENV_STR: envJson.PUBLIC_FLUID_ENV_STR,
        ACM_NAMESPACE: envJson.PUBLIC_ACM_NAMESPACE,
        SEGMENT_WRITE_KEY: envJson.PUBLIC_SEGMENT_WRITE_KEY || '',
        SENTRY_DSN: envJson.PUBLIC_SENTRY_DSN || '',
        RELEASE_CHANNEL: envJson.PUBLIC_RELEASE_CHANNEL || 'develop',
        GIT_SHA: envJson.GIT_SHA,
        REACT_APP_ACS_ENV: envJson.REACT_APP_ACS_ENV || 'PRODUCTION',
        REACT_APP_ACS_REGION: envJson.REACT_APP_ACS_REGION || 'US',
      });
    } catch (error) {
      console.error('Failed to load environment.json', { error });
      return;
    }
  }

  await initOasisApp({
    context: 'web',
    apiUrl: env.HTTP_URL,
    mqttUrl: env.MQTT_URL,
    lmvEnv: env.FORGE_LMV_ENV,
    fluidEnv: env.FLUID_ENV_STR,
    acmNamespace: env.ACM_NAMESPACE,
    segmentWriteKey: env.SEGMENT_WRITE_KEY,
    sentryDsn: env.SENTRY_DSN,
    releaseChannel: releaseChannelSchema.parse(env.RELEASE_CHANNEL),
    gitSha: env.GIT_SHA,
    acsEnv: env.REACT_APP_ACS_ENV,
    acsRegion: env.REACT_APP_ACS_REGION,
  });

  const app = await createApp({
    releaseChannel: env.RELEASE_CHANNEL,
    sentryDsn: env.SENTRY_DSN,
  });

  ReactDOM.createRoot(document.getElementById('root')!).render(app);
})();
