import { Oasis } from './oasis';
import { ApsHttp } from './providers/http/aps-http.provider';
import { OasisHttpProvider } from './providers/http/oasis-http.provider';
import type { ReleaseChannel } from './services/releases/releases.types';
import type { EnvContext } from './types';

export * from './lib/result';
export * from './oasis';
export * from './services/feature-flags/feature-flags.types';
export * from './services/files/files.types';
export * from './services/issues/issues.schemas';
export { IssuesSchemas } from './services/issues/issues.schemas';
export * from './services/issues/issues.types';
export * from './services/releases/releases.schemas';
export * from './services/releases/releases.types';
export * from './services/session/session.types';
export { WorkshopsSchemas } from './services/workshops/workshops.schemas';
export * from './types';

/**
 * @name initOasisApp
 * Kick the tires and initialize all the modules with the provided configuration.
 */
export async function initOasisApp(params: {
  context: EnvContext;
  lmvEnv: string;
  fluidEnv: string;
  acmNamespace: string;
  apiUrl: string;
  mqttUrl: string;
  releaseChannel?: ReleaseChannel;
  segmentWriteKey?: string;
  sentryDsn?: string;
  gitSha: string;
  acsEnv: string;
  acsRegion: string;
}) {
  process = process || {};
  process.env = process.env || {};
  process.env['REACT_APP_ACS_ENV'] = params.acsEnv;
  process.env['REACT_APP_ACS_REGION'] = params.acsRegion;

  if (!params.releaseChannel) {
    params.releaseChannel = 'develop';
  }

  Oasis.Logger.init({
    releaseChannel: params.releaseChannel,
    sentryDsn: params.sentryDsn,
  });

  Oasis.Env.init(params);

  if (params.segmentWriteKey) {
    Oasis.Segment.loadAnalytics(params.segmentWriteKey);
  }

  await Oasis.Pendo.init('4df1398c-2a22-48e9-6ba7-068152b64546');
  await Oasis.FeatureFlags.init(params.releaseChannel);

  OasisHttpProvider.init({
    baseUrl: params.apiUrl,
    releaseChannel: params.releaseChannel,
  });

  ApsHttp.init({
    releaseChannel: params.releaseChannel,
  });

  // if (params.context === 'desktop') {
  //   Oasis.Bridge = await import('./services/bridge/bridge.service').then(m => m.Bridge);
  // }

  // Hackathon CORS stuff
  //   const cachedFetch = window.fetch;
  //   window.fetch = (input: string | URL | Request, init?: RequestInit) => {
  //     try {
  //       if (typeof input === 'string' && includesProxied(input)) {
  //         input = new URL(input);
  //         input.host = 'plangrid-proxy.fly.dev';
  //         return cachedFetch(input, init);
  //       }

  //       if (input instanceof Request && includesProxied(input.url)) {
  //         const url = new URL(input.url);
  //         url.host = 'plangrid-proxy.fly.dev';
  //         input = { ...input, url: url.toString() };
  //         return cachedFetch(input, init);
  //       }

  //       if (input instanceof URL && includesProxied(input.href)) {
  //         input.host = 'plangrid-proxy.fly.dev';
  //         return cachedFetch(input, init);
  //       }

  //       return cachedFetch(input, init);
  //     } catch (error) {
  //       Oasis.Logger.warn({ msg: 'Failed to proxy `fetch` request', error, params });
  //       throw error;
  //     }
  //   };

  //   const cachedOpen = XMLHttpRequest.prototype.open;
  //   XMLHttpRequest.prototype.open = function (
  //     method: string,
  //     url: string | URL,
  //     async?: boolean,
  //     user?: string,
  //     password?: string
  //   ) {
  //     try {
  //       if (typeof url === 'string') {
  //         url = new URL(url);
  //       }

  //       if (url.pathname.includes('/dm/v3/projects')) {
  //         url.host = location.host;
  //       }

  //       return cachedOpen.call(this, method, url, async ?? false, user, password);
  //     } catch (error) {
  //       Oasis.Logger.warn({ msg: 'Failed to proxy `XMLHttpRequest` request', error, params });
  //       throw error;
  //     }
  //   };
}

// const proxied = ['plangrid'];

// function includesProxied(input: string) {
//   for (const segment of proxied) {
//     if (input.includes(segment)) {
//       return true;
//     }
//   }

//   return false;
// }

/**
 * Bind the `adsk` object to the window for easy access in the browser console.
 */
(window as any).adsk = {
  toggleEnv() {
    console.warn('Deprecated: Use `adsk.toggleDevMode()` instead');
    Oasis.Env.setDevMode(!Oasis.Env.store.isDevMode);
  },

  toggleDevMode() {
    Oasis.Env.setDevMode(!Oasis.Env.store.isDevMode);
  },

  useFluid(flag = false) {
    Oasis.Env.setUseFluid(flag);
    (window as any).DEFAULT_DOCUMENT_ID = '1691134b-6617-466d-8b48-504d57d95693';
    (window as any).DEFAULT_WORKSHOP_ID = 'test1';
  },
};
