import { Oasis } from '@oasis/sdk';
import { useQuery } from '@tanstack/react-query';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { OasisError } from '~/shared/utils/oasis-error';

export const usersQueries = {
  useListActiveWorkshops(projectId: string) {
    useQuery({
      queryKey: ['@me', 'active', 'workshops'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Users.listActiveWorkshops(projectId, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Users.useListActiveWorkshops]');
        return res.value;
      },
      networkMode: 'always',
      refetchInterval: 1000 * 10,
    });
  },

  useGetActiveModel(projectId: string, opts?: { enabled?: boolean }) {
    const $session = Oasis.Session.useStore();
    const enabled = !!projectId || opts?.enabled === true;

    return useQuery({
      queryKey: ['@me', 'active', 'model', projectId],
      queryFn: async () => {
        const activeWorkshopId = $session.activeWorkshop?.id;

        if (!activeWorkshopId) {
          return { inProject: false, model: null };
        }

        const workshop = await Oasis.Workshops.getWorkshopSettings(activeWorkshopId);

        if (!workshop.ok) {
          return { inProject: false, model: null };
        }

        // The murn - `{projectId}_{modelId}` is part of the new murnxfrm sequence - `M:urn;T:x,y,z;R:x,y,z,w;S:x,y,z`;
        const murnxfrmValue = workshop.value.murnxfrm?.value;
        // Parse ProjectId and VersionId from murnxfrmValue
        let [versionProjectId, versionId] =
          typeof murnxfrmValue === 'string' ? murnxfrmValue.replace('M:', '').split(/_(.*?)(;|$)/) : ['', ''];

        /**
         * TODO: Supporting both murnxfrm and the new distinct values. Eventually we can remove
         * all of this complete logic to extract values from the delimited string.
         */
        if (typeof workshop.value.prid?.value === 'string') {
          versionProjectId = workshop.value.prid.value;
        }
        if (typeof workshop.value.murn?.value === 'string') {
          versionId = workshop.value.murn.value;
        }

        const inProject = projectId === versionProjectId;

        if (!versionId) {
          return { inProject, model: null };
        }

        const documentVersion = await Oasis.Files.findDocumentVersionById({
          projectId,
          documentVersionId: versionId,
        });

        if (!documentVersion.ok) {
          return { inProject, model: null };
        }

        const activeViewGuid = workshop.value.mvid?.value as string | undefined;
        const activeView = await Oasis.Files.find3dView({
          projectId,
          documentVersionId: versionId,
          viewGuid: activeViewGuid,
        });

        if (!activeView.ok) {
          NotificationManager.push({
            status: 'warning',
            content: 'Unable to find active 3d view. Has your model changed?',
          });
        }

        // Only persist the active folder urn if it's not already set.
        // We don't want to override if someone has navigated around but then someone else opens a model.
        if (
          !Oasis.Storage.getTemporary('activeFolderUrn') &&
          documentVersion.value.data.attributes.extension.data.originalItemUrn
        ) {
          const document = await Oasis.Files.findDocumentById({
            projectId,
            documentId: documentVersion.value.data.attributes.extension.data.originalItemUrn,
          });

          if (document.ok) {
            Oasis.Storage.setTemporary('activeFolderUrn', document.value.folderId);
          }
        }

        return {
          versionId,
          inProject,
          model: documentVersion.value,
          activeViewGuid,
          activeView: activeView.ok ? activeView.value : null,
        };
      },
      enabled,
      networkMode: 'always',
      refetchInterval: 1000 * 5,
    });
  },

  useListUsersDevices() {
    return useQuery({
      queryKey: ['@me', 'devices'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Users.listUsersDevices({ signal });
        if (!res.ok) throw new OasisError(res.error, '[Users.useListUsersDevices]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },
};
