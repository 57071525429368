import { z } from 'zod';
import { CommonSchemas } from './lib/common.schemas';
import { MqttIncomingPayloads } from './providers/mqtt/mqtt.schemas';
import { WorkshopsSchemas } from './services/workshops/workshops.schemas';

export type Timeout = ReturnType<typeof setTimeout>;
export type Optionull<T> = T | undefined | null;

export const OasisSchemas = {
  /**
   * Common
   */

  Device: z.union([z.literal('desktop'), z.literal('vr')]),

  EnvContext: z.union([
    z.literal('web'),
    z.literal('desktop'),
    z.literal('vr'),
    z.literal('vr-workshop'),
    z.literal('vr-homespace'),
  ]),

  EnvReleaseChannel: z.union([z.literal('develop'), z.literal('devstg'), z.literal('alpha'), z.literal('beta'), z.literal('prod')]),

  TauriToastMessage: z.object({
    status: z.enum(['info', 'success', 'warning', 'error', 'none']),
    content: z.string(),
  }),
};

export type Device = z.infer<typeof OasisSchemas.Device>;
export type EnvContext = z.infer<typeof OasisSchemas.EnvContext>;
export type EnvReleaseChannel = z.infer<typeof OasisSchemas.EnvReleaseChannel>;
export type TauriToastMessage = z.infer<typeof OasisSchemas.TauriToastMessage>;

export type UserProfileSettings = z.infer<typeof CommonSchemas.userProfileSettings>;
export type TauriUpdateStatus = z.infer<typeof CommonSchemas.tauriUpdateStatus>;
export type UnrealUpdateStatus = z.infer<typeof CommonSchemas.unrealUpdateStatus>;
export type UnrealProcessStatus = z.infer<typeof CommonSchemas.unrealProcessStatus>;

export interface PaginatedResponse<T> {
  results: T[];
  pagination: {
    limit: number;
    offset: number;
    totalResults: number;
  };
}

export type SubscriptionPayloads = z.infer<typeof MqttIncomingPayloads>;
export type SubscriptionHandler = (payload: SubscriptionPayloads, topic: string) => void;

export type FileUploadState =
  | { status: 'ERROR'; message: string }
  | { status: 'QUEUED' }
  | { status: 'INITIALIZING' }
  | { status: 'UPLOADING'; progress: number }
  | { status: 'PREPROCESSING' }
  | { status: 'PROCESSING' }
  | { status: 'DONE'; uploadedVersionUrn: string };

export type WorkshopPermissions = 'VIEW' | 'EDIT' | 'MANAGE';

/**
 * Models
 */

export interface FolderContent {
  id: string;
  latestVersionId: string;
  thumbnailId?: string;
  name: string;
  type: 'folders' | 'items';
  fileType: string | undefined;
  updatedAt: string;
  updatedBy: string;
  updatedById: string;
  isProcessing: boolean;
  supportedInLMV: boolean;
  supportedInVR: boolean;
  storageSize: number | undefined;
}

export interface Project {
  id: string;
  accountId: string;
  accountName: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  platform: 'acc' | 'bim360';
}

export interface UserProfile {
  companyName: string;
  companyType: string;
  jobTitle: string;
  phone: string;
}

export interface Workshop {
  id: string;
  name: string;
  settings: {
    description: string;
  };
}

export interface OasisResponses {
  Workshops: {
    FindById: z.infer<typeof WorkshopsSchemas.findWorkshopById>;
  };
}
