import { CollaborationExtension, InitializeExtension } from '@oasis/fluid-interop';
import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { proxy } from 'valtio';
import { useProjectContext } from '~/shared/contexts/project-context';
import { useWorkshopState } from '~/shared/hooks/use-workshop-state';
import { Queries } from './queries';
import { useUnrealLmvCamerasSync } from './use-lmv-debugger';

export type ExtensionLoadingState = 'INITIAL' | 'LOADING' | 'LOADED' | 'FAILED';

export const CollaborationExtensionState = proxy<{
  status: ExtensionLoadingState;
  reasons: string[];
}>({
  // The status of the collaboration extension loading. By default, it is set to loading.
  status: 'LOADING',
  // List of reasons for why the collaboration extension is not loaded.
  reasons: [],
});

const resetState = () => {
  CollaborationExtensionState.status = 'INITIAL';
  CollaborationExtensionState.reasons = [];
};

export const useCollaborationExtension = ({
  viewer,
  versionId,
  modelLoaded,
}: {
  viewer: Autodesk.Viewing.GuiViewer3D | undefined;
  versionId: string;
  modelLoaded: boolean;
}) => {
  const {
    workshopConnection,
    activeWorkshopId,
    connectionErrors,
    status: connectionStatus,
  } = useWorkshopState({
    connectFluidBasedOnContext: true,
  });
  const { projectId } = useProjectContext();
  const activeModelRes = Queries.Users.useGetActiveModel(projectId);
  const modelUrn = activeModelRes.data?.model?.data.id;

  useUnrealLmvCamerasSync({ viewer, fluidConnection: workshopConnection });
  useEffect(() => {
    return () => resetState();
  }, []);

  useEffect(() => {
    let destroy: () => void;
    // If the user is active in a workshop we sync model visibility.
    if (
      viewer &&
      modelLoaded &&
      workshopConnection !== undefined &&
      // We check that the workshop connection is not closed, which indicate that the extension is unloaded
      !workshopConnection.isClosed &&
      activeWorkshopId &&
      // If the active workshop has different model urn than the current model urn, then we should not sync the model visibility
      modelUrn === versionId
    ) {
      CollaborationExtensionState.status = 'LOADING';
      Oasis.Logger.info('[app/Viewer] Initializing collaboration extension and binding to model visibility.');
      console.assert(Autodesk.Viewing, 'Autodesk.Viewing is not defined.');
      CollaborationExtension(Autodesk.Viewing);
      console.assert(viewer, 'viewer is not defined.');
      viewer.loadExtension('Autodesk.ConcurrentCollaboration', {});
      const ext = viewer.getExtension('Autodesk.ConcurrentCollaboration');
      let extensionDestroy = () => {};

      CollaborationExtensionState.reasons = [];
      try {
        const { destroy } = InitializeExtension(ext, workshopConnection, modelUrn);
        extensionDestroy = destroy;
        CollaborationExtensionState.status = 'LOADED';
      } catch (err) {
        CollaborationExtensionState.status = 'FAILED';
        console.error('Failed to initialize collaboration extension due to error: ', err);
      }

      destroy = () => {
        Oasis.Logger.info('[app/Viewer] Unregister collaboration extension: No active workshop, showing all layers.');
        CollaborationExtensionState.status = 'INITIAL';
        extensionDestroy();
        if (viewer) {
          if ((viewer as any).impl?.layers !== undefined) {
            viewer.showAll();
          } else {
            console.warn('Viewer object already deiniitialized.');
          }
        }
      };
    } else {
      CollaborationExtensionState.reasons = getReasons({
        modelUrn,
        versionId,
        workshopConnection,
        activeWorkshopId,
        modelLoaded,
        viewer,
        connectionErrors,
      });
    }
    return () => {
      resetState();
      destroy && destroy();
    };
  }, [versionId, viewer, modelLoaded, workshopConnection, activeWorkshopId, modelUrn, connectionErrors]);

  return {
    connectionStatus,
  };
};

const getReasons = ({
  modelUrn,
  versionId,
  workshopConnection,
  activeWorkshopId,
  modelLoaded,
  viewer,
  connectionErrors,
}: {
  modelUrn: string | undefined;
  versionId: string;
  workshopConnection: any | undefined;
  activeWorkshopId: string | undefined;
  modelLoaded: boolean;
  viewer: Autodesk.Viewing.GuiViewer3D | undefined;
  connectionErrors: string[];
}) => {
  const listOfReasons: string[] = [];

  if (activeWorkshopId === undefined) {
    const reason = `Not in active workshop`;
    listOfReasons.push(reason);
    // No need to check other conditions if we are not in a workshop.
    return listOfReasons;
  }

  if (modelUrn !== versionId) {
    const reason = `The model viewed is not the same as the model in the active workshop: ${modelUrn} !== ${versionId}`;
    listOfReasons.push(reason);
    /* If modelUrn in undefined that would mean that the active's workshop has no model associated with it.
     *  In this case, we should not sync the model visibility. Because we can't tell if are currently viewing the same model as the active workshop.
     */
    if (modelUrn === undefined) {
      Oasis.Logger.info(`Model urn is undefined for active workshop: ${activeWorkshopId}`);
    }
    return listOfReasons;
  }

  if (!modelLoaded) {
    const reason = `Model is not fully loaded`;
    listOfReasons.push(reason);
  }

  if (viewer === undefined) {
    const reason = `LMV is not loaded`;
    listOfReasons.push(reason);
  }

  if (workshopConnection === undefined) {
    const reason = `Fluid is not connected to workshop's document ${
      connectionErrors.length ? `:(${connectionErrors.join(', ')})` : ''
    }`;
    if (connectionErrors && connectionErrors.length > 0) {
      CollaborationExtensionState.status = 'FAILED';
    }
    listOfReasons.push(reason);
  }

  return listOfReasons;
};
