import { z } from 'zod';

export const releaseChannelSchema = z.union([
  z.literal('dev'),
  z.literal('develop'),
  z.literal('devstg'),
  z.literal('localhost'),
  z.literal('alpha'),
  z.literal('beta'),
  z.literal('main'),
  z.literal('prod'),
]);

export const ReleasesSchemas = {
  getReleaseMeta: z.union([
    z.string(),
    z.object({
      app: z.union([
        z.literal('WorkshopXR_Launcher'),
        z.literal('WorkshopXR_Viewer'),
        z.literal('WorkshopXR_Viewer_Symbols'),
        z.literal('WorkshopXR_Unreal'),
        z.literal('WorkshopXR_Unreal_Symbols'),
      ]),
      channel: releaseChannelSchema,
      url: z.string(),
      notes: z.string(),
      version: z.string(),
      platform: z.union([z.literal('windows-x86_64'), z.literal('linux-x86_64'), z.literal('android')]),
    }),
  ]),
};
