import { Env } from '../../services/env/env.service';

export const LaunchDarkly = {
  /**
   * @name getClientId
   * Returns the LaunchDarkly client ID based on the release channel.
   * Defaults to the production ID.
   */
  getClientId(releaseChannel = Env.store.releaseChannel) {
    switch (releaseChannel) {
      case 'develop':
        return '6495f1f293945a13a9b06294';
      case 'devstg':
        return '6495f1f293945a13a9b06294'; // using same value as dev
      case 'alpha':
        return '6495f1f1a3b28313bc3cf800';
      case 'beta':
        return '6495f1f1acf74e135e35ac34';
      default:
        return '6495f1ef3f7fdc12d1e96d84'; // default to prod
    }
  },
};
