import ky from 'ky';
import { ReleaseChannel } from '../../services/releases/releases.types';
import { createAuthHook, createRefreshHook } from './utils';

const adskUrlEnv = {
  localhost: {
    url: 'https://developer.api.autodesk.com',
    trialsUrl: 'https://api.trials.autodesk.com',
    enterpriseUrl: 'https://enterprise-api.autodesk.com',
    dmV3Url: location.origin,
    products: {
      acc: {
        'dm/v1': 'dm/v1',
        'issues/v1': 'construction/issues/v1/projects',
        'oss/v2': 'oss/v2/buckets/wip.dm.prod',
        'modelderivative/v2': 'modelderivative/v2',
        'authentication/v2': 'authentication/v2',
        'project/v1': 'project/v1',
      },
      bim360: {
        'issues/v2': 'issues/v2/containers',
      },
    },
  },

  production: {
    url: 'https://developer.api.autodesk.com',
    trialsUrl: 'https://api.trials.autodesk.com',
    enterpriseUrl: 'https://enterprise-api.autodesk.com',
    dmV3Url: 'https://developer.api.autodesk.com',
    products: {
      acc: {
        'dm/v1': 'dm/v1',
        'issues/v1': 'construction/issues/v1/projects',
        'oss/v2': 'oss/v2/buckets/wip.dm.prod',
        'modelderivative/v2': 'modelderivative/v2',
        'authentication/v2': 'authentication/v2',
        'project/v1': 'project/v1',
      },
      bim360: {
        'issues/v2': 'issues/v2/containers',
      },
    },
  },
  staging: {
    url: 'https://developer-stg.api.autodesk.com',
    trialsUrl: 'https://api.trials-stg.autodesk.com',
    enterpriseUrl: 'https://enterprise-api-stg.autodesk.com',
    dmV3Url: 'https://developer-stg.api.autodesk.com',
    products: {
      acc: {
        'dm/v1': 'dm-staging/v1',
        'issues/v1': 'construction/issues-stg/v1/projects',
        'oss/v2': 'oss/v2/buckets/wip.dm.stg',
        'modelderivative/v2': 'modelderivative/v2',
        'authentication/v2': 'authentication/v2',
        'project/v1': 'project/v1',
      },
      bim360: {
        'issues/v2': 'issues-stg/v2/containers',
      },
    },
  },
};

const oasisUrlEnv = {
  localhost: adskUrlEnv.localhost,
  dev: adskUrlEnv.production,
  develop: adskUrlEnv.production,
  devstg: adskUrlEnv.staging,
  alpha: adskUrlEnv.staging,
  beta: adskUrlEnv.production,
  prod: adskUrlEnv.production,
  main: adskUrlEnv.production,
} satisfies Record<ReleaseChannel, typeof adskUrlEnv.localhost>;

const baseClient = ky.extend({
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-US',
  },
  retry: {
    limit: 1,
    statusCodes: [401],
    methods: ['get', 'post', 'put', 'patch', 'delete'],
  },
  timeout: 60_000,
});

export const ApsHttp = {
  releaseChannel: 'develop' as ReleaseChannel | 'localhost',
  urlBase: oasisUrlEnv.develop.url,
  client: baseClient,
  trialsClient: baseClient,
  enterpriseClient: baseClient,
  dmV3Client: baseClient,

  accessToken: '',

  init(params: { releaseChannel: ReleaseChannel; refreshSession?: () => string | Promise<string> }) {
    ApsHttp.releaseChannel = window.location.host.includes('local') ? 'localhost' : params.releaseChannel;
    ApsHttp.urlBase = oasisUrlEnv[params.releaseChannel].url;

    const authHeader = createAuthHook();
    const refreshSession = createRefreshHook();

    // Extend our base Http client with our auth hooks.
    const hooks = {
      beforeRequest: [authHeader],
      beforeRetry: [refreshSession],
    };

    ApsHttp.client = baseClient.extend({
      hooks,
      prefixUrl: oasisUrlEnv[params.releaseChannel].url,
    });

    ApsHttp.trialsClient = baseClient.extend({
      hooks,
      prefixUrl: oasisUrlEnv[params.releaseChannel].trialsUrl,
      credentials: 'omit',
    });

    ApsHttp.enterpriseClient = baseClient.extend({
      hooks,
      prefixUrl: oasisUrlEnv[params.releaseChannel].enterpriseUrl,
      credentials: 'omit',
    });

    ApsHttp.dmV3Client = baseClient.extend({
      hooks,
      prefixUrl: oasisUrlEnv[ApsHttp.releaseChannel].dmV3Url,
    });
  },

  url(...segments: string[]) {
    const path = oasisUrlEnv[ApsHttp.releaseChannel]['url'];
    return [path, ...segments].filter(Boolean).join('/');
  },

  path<T extends 'acc' | 'bim360'>(
    product: T,
    key: keyof (typeof oasisUrlEnv)['develop']['products'][T],
    ...segments: string[]
  ) {
    const path = oasisUrlEnv[ApsHttp.releaseChannel].products[product][key];
    return [path, ...segments].filter(Boolean).join('/');
  },
};
