import { ConnectionManager } from '../connection/connectionManager';
import { Extension } from './globals';

import { setupViewerState } from './state';

export interface CameraInfo {
  position: ReadonlyArray<number>;
  direction: ReadonlyArray<number>;
  rotation: ReadonlyArray<number>;
  target: ReadonlyArray<number>;
  up: ReadonlyArray<number>;
}

export function setup(
  extension: Extension,
  connectionManager: ConnectionManager,
  modelUrn: string
): { destroy: () => void } {
  const { viewer } = extension;

  const { destroy: destroyState } = setupViewerState(
    connectionManager,
    viewer,
    modelUrn
  );

  return {
    destroy: () => {
      destroyState();
    },
  };
}
