import { AsyncQueue, ErrorUtils, FileUtils, ProjectUtils } from '@oasis/utils';
import { HTTPError, type Options } from 'ky';
import { Err, Ok, type Result } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { ApsHttp } from '../../providers/http/aps-http.provider';
import { Segment } from '../../providers/segment/segment.provider';
import { FileUploadState } from '../../types';
import { Logger } from '../logger/logger.service';
import { FilesSchemas, type ModelStatus } from './files.schemas';
import { FileSearchFilters } from './files.types';

const MULTIPART_THRESHOLD = 1024 * 1024 * 25; // X MB
const CHUNK_SIZE = 1024 * 1024 * 10; // X MB

const _parse = HttpUtils.createScopedParser('Files', FilesSchemas);
const documentVersionManifestCache = new Map<string, string>();

export const Files = {
  /**
   * @name uploadFile
   * Starts the upload of a file.
   */
  async uploadFile(params: {
    projectId: string;
    file: File;
    parentFolderUrn: string;
    onStateChange(state: FileUploadState): void;
    getIsCancelled(): boolean;
  }) {
    try {
      Segment.track('File Uploaded', {
        projectId: params.projectId,
        parentFolderUrn: params.parentFolderUrn,
        file: {
          size: params.file.size,
          name: params.file.name,
          type: FileUtils.getFileType({ name: params.file.name, type: 'items' }),
        },
      });

      const projectId = ProjectUtils.formatId(params.projectId, { prefix: true });

      // Validate filename
      params.onStateChange({ status: 'INITIALIZING' });
      const validate = FileUtils.validateFilename(params.file.name);

      if (validate.error) {
        params.onStateChange({ status: 'ERROR', message: validate.error });
        return Err({ code: 'FILENAME_VALIDATION_FAILED' });
      }

      if (params.getIsCancelled()) {
        return Ok(true);
      }

      // Create the storage destination
      const createStorage = await _createStorageLocation({
        projectId,
        fileName: params.file.name,
        parentFolderId: params.parentFolderUrn,
      });

      if (!createStorage.ok) {
        params.onStateChange({ status: 'ERROR', message: 'Failed to create upload location.' });
        return Err({ code: 'CREATE_OBJECT_FAILED' });
      }

      if (params.getIsCancelled()) {
        return Ok(true);
      }

      const [bucketKey, objectKey] = createStorage.value.data.id.replace('urn:adsk.objects:os.object:', '').split('/');

      if (!bucketKey || !objectKey) {
        return Err({ code: 'MALFORMED_STORAGE_ID' });
      }

      // Split our file into chunks (if needed)
      const chunks = _splitFile(params.file);

      // Create the signed S3 urls for each chunk
      const getSignedUrls = await _getSignedUploadUrls({
        objectKey,
        parts: chunks.length,
        firstPart: 1,
      });

      if (!getSignedUrls.ok) {
        params.onStateChange({ status: 'ERROR', message: 'Failed to create upload destination.' });
        return Err({ code: 'GET_SIGNED_UPLOAD_URLS_FAILED' });
      }

      if (params.getIsCancelled()) {
        return Ok(true);
      }

      // Push each chunk to an async queue with a max concurrency
      const queue = new AsyncQueue(3);
      const urlCount = getSignedUrls.value.urls.length;
      const progressIncrement = 100 / urlCount;
      const chunkProgresses: number[] = new Array(urlCount).fill(0);
      let queueErrored = false;

      for (let index = 0; index < urlCount; index++) {
        const url = getSignedUrls.value.urls[index];
        const chunk = chunks[index];

        if (params.getIsCancelled()) {
          return Ok(true);
        }

        if (url && chunk) {
          queue.push(async () => {
            const upload = await _uploadChunk({
              url,
              chunk,
              onProgress(percentDecimal, xhr) {
                if (params.getIsCancelled()) {
                  queue.dump();
                  return xhr.abort();
                }

                chunkProgresses[index] = percentDecimal * progressIncrement;

                params.onStateChange({
                  status: 'UPLOADING',
                  progress: Math.floor(chunkProgresses.reduce((prev, current) => prev + current, 0)),
                });
              },
            });

            if (!upload.ok && !params.getIsCancelled()) {
              queueErrored = true;
            }
          });
        }
      }

      await queue.observe();

      if (queueErrored) {
        params.onStateChange({ status: 'ERROR', message: 'File upload failed. Please try again.' });
        return Err({ code: 'UPLOAD_CHUNKS_ERRORED' });
      }

      if (params.getIsCancelled()) {
        return Ok(true);
      }

      // After all the chunks have uploaded, complete the process.
      params.onStateChange({ status: 'PREPROCESSING' });
      const finalizeUpload = await _finalizeUpload({
        bucketKey,
        objectKey,
        uploadKey: getSignedUrls.value.uploadKey,
      });

      if (!finalizeUpload.ok) {
        params.onStateChange({ status: 'ERROR', message: 'Failed to finalize upload. Please try again.' });
        return Err({ code: 'FINALIZE_UPLOAD_FAILED' });
      }

      if (params.getIsCancelled()) {
        return Ok(true);
      }

      // Create the initial version of the file
      const initialVersion = await _createDocument({
        file: params.file,
        parentFolderUrn: params.parentFolderUrn,
        projectId: params.projectId,
        objectId: finalizeUpload.value.objectId,
      });

      if (!initialVersion.ok) {
        params.onStateChange({
          status: 'ERROR',
          message: ErrorUtils.mapCodeToMessages({
            code: initialVersion.error.code,
            defaultMessage: 'Something went wrong creating initial version. Please try again.',
            messages: {
              CONFLICT: 'That file already exists. Please rename the file and try again.',
              FORBIDDEN: "You don't have permission to upload to this folder.",
            },
          }),
        });
        return initialVersion;
      }

      if (params.getIsCancelled()) {
        return Ok(true);
      }

      const uploadedVersionUrn = initialVersion.value.document.tipVersionUrn;

      if (!uploadedVersionUrn) {
        params.onStateChange({ status: 'ERROR', message: 'Unexpected response. Try again.' });
        return Err({ code: 'UNEXPECTED_RESPONSE' });
      }

      params.onStateChange({ status: 'PROCESSING' });

      const isProcessed = await Files.monitorProcessingState({
        projectId: params.projectId,
        documentVersionId: uploadedVersionUrn,
      });

      if (!isProcessed.ok) {
        params.onStateChange({ status: 'ERROR', message: 'File processing failed.' });
        return Err({ code: 'PROCESSING_FAILED' });
      }

      params.onStateChange({
        status: 'DONE',
        uploadedVersionUrn,
      });

      return Ok(true);
    } catch (error) {
      Logger.error({ error, msg: '[Files.uploadFile]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async monitorProcessingState(params: {
    projectId: string;
    documentVersionId: string;
    onProcessingComplete?: () => void;
    onProcessingError?: () => void;
    onProgress?: (valueString: string) => void;
    getShouldContinue?: () => boolean;
    shouldDelay?: boolean;
    manifestRetry?: number;
  }): Promise<Result<true, { code: 'PROCESSING_FAILED' | 'MANIFEST_ERROR' | 'STOPPED' }>> {
    if (params.getShouldContinue && !params.getShouldContinue()) {
      return Err({ code: 'STOPPED' });
    }

    if (params.shouldDelay) {
      await new Promise(r => setTimeout(r, 5000));
    }

    const manifest = await Files.getModelManifest({
      projectId: params.projectId,
      documentVersionId: params.documentVersionId,
    });

    // Sometimes the manifest isn't immediately available after uploading a file, so we auto-retry.
    const manifestRetry = params.manifestRetry ?? 1;

    if (!manifest.ok && manifestRetry < 5) {
      await new Promise(r => setTimeout(r, manifestRetry * 1000));
      return Files.monitorProcessingState({ ...params, manifestRetry: manifestRetry + 1 });
    }

    if (!manifest.ok) {
      params.onProcessingError?.();
      return Err({ code: 'MANIFEST_ERROR' });
    }

    return new Promise(resolve => {
      if (manifest.value.status === 'failed' || manifest.value.status === 'timeout') {
        params.onProcessingError?.();
        return resolve(Err({ code: 'PROCESSING_FAILED' }));
      }

      if (manifest.value.status === 'success') {
        params.onProcessingComplete?.();
        return resolve(Ok(true));
      }

      if (manifest.value.status === 'inprogress' && manifest.value.derivatives.length && manifest.value.progress) {
        params.onProgress?.(manifest.value.progress);
      }

      return resolve(Files.monitorProcessingState({ ...params, shouldDelay: true }));
    });
  },

  async getProcessingState(params: {
    projectId: string;
    documentVersionId: string;
    manifestRetry?: number;
  }): Promise<{ status: ModelStatus; progress: string }> {
    const manifestRetry = params.manifestRetry ?? 1;

    const manifest = await Files.getModelManifest({
      projectId: params.projectId,
      documentVersionId: params.documentVersionId,
    });

    if (!manifest.ok) {
      if (manifestRetry < 5) {
        await new Promise(r => setTimeout(r, manifestRetry * 1000));
        return Files.getProcessingState({ ...params, manifestRetry: manifestRetry + 1 });
      }

      return { status: 'unknown', progress: 'incomplete' };
    }

    return {
      status: manifest.value.status,
      progress:
        manifest.value.status === 'inprogress' && manifest.value.derivatives.length > 0
          ? manifest.value.progress
          : 'waiting...',
    };
  },

  async findDocumentById(params: { projectId: string; documentId: string }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId, { prefix: true });
      const res = await ApsHttp.client.get(`data/v1/projects/${projectId}/items/${params.documentId}`).json();
      const { data, included } = _parse('findDocumentById', res);

      // Create a Map of version statuses so we aren't looping each time we want to
      // determine the `isProcessing` status as we're mapping over the folder content.
      const isProcessingLookup = new Map<string, boolean>();
      let fileType = '';

      if (included) {
        for (const version of included) {
          // use the first version with a file type
          if (!fileType && version.attributes.fileType) {
            fileType = version.attributes.fileType;
          }

          const isProcessing = version.attributes.extension.data.processState !== 'PROCESSING_COMPLETE';

          isProcessingLookup.set(version.id, isProcessing);
        }
      }

      const latestVersionId = data.relationships.tip.data.id;

      return Ok({
        id: data.id,
        latestVersionId,
        isProcessing: isProcessingLookup.get(latestVersionId) || false,
        filename: data.attributes.displayName,
        fileType,
        folderId: data.relationships.parent.data.id,
        createTime: data.attributes.createTime,
        createUserName: data.attributes.createUserName,
        createUserId: data.attributes.createUserId,
        lastModifiedTime: data.attributes.lastModifiedTime,
        lastModifiedUserName: data.attributes.lastModifiedUserName,
      });
    } catch (error) {
      Logger.error({ error, msg: '[Files.findDocumentById]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  /**
   * @name getModelManifest
   * Retrieves the manifest of the specified source design.
   * Manifests may not be immediately available after uploading a file, so we auto-retry.
   * @link https://aps.autodesk.com/en/docs/model-derivative/v2/reference/http/manifest/urn-manifest-GET/
   */
  async getModelManifest(params: { projectId: string; documentVersionId: string }, retryCount = 0) {
    try {
      if (retryCount < 5) {
        await new Promise(r => setTimeout(r, retryCount * 1000));
      } else {
        return Err({ code: 'NOT_FOUND' });
      }

      let derivativeId = documentVersionManifestCache.get(params.documentVersionId);

      if (!derivativeId) {
        const version = await Files.findDocumentVersionById(params);

        if (!version.ok) {
          return version;
        }

        derivativeId = version.value.data.relationships.derivatives?.data.id;

        if (!derivativeId) {
          // TODO: How do we want to handle files that are deleted in ACC? Wipe out workshop session state?
          return Err({ code: 'NOT_FOUND' });
        }

        documentVersionManifestCache.set(params.documentVersionId, derivativeId);
      }

      const res = await ApsHttp.client.get(`modelderivative/v2/designdata/${derivativeId}/manifest`).json();
      const data = _parse('getModelManifest', res);

      return Ok(data);
    } catch (error) {
      // They return an empty 404 with a `Content-Type: application/json` header so parsing it
      // as json in HttpUtils.handleError will throw blow up..
      if (error instanceof HTTPError && error.response.status === 404) {
        return Err({ code: 'NOT_FOUND' });
      }
      return HttpUtils.handleError(error, '[Files.getModelManifest]');
    }
  },

  async list3dViews(params: { projectId: string; documentVersionId: string }) {
    try {
      const manifest = await Files.getModelManifest(params);

      if (!manifest.ok) {
        return manifest;
      }

      const views: Array<{ guid: string; name: string; urn?: string }> = [];

      for (const derivative of manifest.value.derivatives) {
        for (const view of derivative.children) {
          if (view.role === '3d') {
            const thumbnail = view.children?.find(child => child.role === 'thumbnail');

            views.push({
              guid: view.guid,
              name: view.name ?? '3D View',
              urn: thumbnail?.urn,
            });
          }
        }
      }

      return Ok(views);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.list3dViews]');
    }
  },

  async find3dView(params: { projectId: string; documentVersionId: string; viewGuid: string | undefined }) {
    try {
      let view: Extract<Awaited<ReturnType<typeof Files.list3dViews>>, { ok: true }>['value'][number] | undefined;

      if (params.viewGuid) {
        const views = await Files.list3dViews(params);

        if (views.ok) {
          view = views.value.find(view => view.guid === params.viewGuid);
        }
      }

      return Ok(view);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.find3dView]');
    }
  },

  async getDerivativeThumbnail(params: { urn: string; guid?: string }, opts?: Options) {
    try {
      const searchParams = new URLSearchParams([['width', '420']]);

      if (params.guid) {
        searchParams.set('guid', params.guid);
      }

      return Ok(
        await ApsHttp.client
          .get(`derivativeservice/v2/thumbnails/${params.urn}`, {
            ...opts,
            searchParams,
          })
          .blob()
      );
    } catch (error) {
      return Err({ code: 'NOT_FOUND' });
    }
  },

  /**
   * @name getSignedUrl
   * Get a signed url from an oss bucket's object
   */
  async getSignedUrl(urn: string, opts?: Options) {
    try {
      const id = urn.split('/').pop() ?? '';
      const url = ApsHttp.path('acc', 'oss/v2', 'objects', id);

      const searchParams = new URLSearchParams([
        ['expiration', '60'],
        ['useCdn', 'true'],
      ]);

      return Ok(await ApsHttp.client.get(url, { ...opts, searchParams }).blob());
    } catch (error) {
      Logger.error({ error, msg: '[Files.getSignedUrl]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async listDocumentsById(params: { projectId: string; urns: string[] }) {
    try {
      const res = await ApsHttp.client
        .post(`data/v1/projects/${params.projectId}/commands`, {
          body: JSON.stringify({
            jsonapi: {
              version: '1.0',
            },
            data: {
              type: 'commands',
              attributes: {
                extension: {
                  type: 'commands:autodesk.core:ListItems',
                  version: '1.1.0',
                },
              },
              relationships: {
                resources: {
                  data: params.urns.map(urn => ({
                    type: 'items',
                    id: urn,
                  })),
                },
              },
            },
          }),
        })
        .json();

      const data = _parse('listDocumentsById', res);

      return Ok(
        data.data.relationships.resources.data.map(document => ({
          id: document.id,
          displayName: document.meta.attributes.displayName,
        }))
      );
    } catch (error) {
      Logger.error({ error, msg: '[Files.listDocumentsById]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async moveDocuments(params: { projectId: string; targetFolderUrn: string; documentUrns: string[] }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const targetFolderUrn = encodeURIComponent(params.targetFolderUrn);

      await ApsHttp.client.post(`dm/v3/projects/${projectId}/documents:move?targetFolder=${targetFolderUrn}`, {
        body: JSON.stringify(params.documentUrns.map(urn => ({ urn }))),
      });

      Segment.track('Documents Moved', params);

      return Ok({ projectId, targetFolderUrn });
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.moveDocuments]');
    }
  },

  async copyDocuments(params: { projectId: string; targetFolderUrn: string; documentUrns: string[] }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const targetFolderUrn = encodeURIComponent(params.targetFolderUrn);

      const res = await ApsHttp.dmV3Client.post(
        `dm/v3/projects/${projectId}/documents:copy?targetFolder=${targetFolderUrn}`,
        {
          body: JSON.stringify(params.documentUrns.map(urn => ({ urn }))),
        }
      );

      const data = _parse('copyDocuments', res);

      Segment.track('Documents Copied', params);

      return Ok(data);
    } catch (error) {
      Logger.error({ error, msg: '[Files.copyDocuments]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async findDocumentVersionById(params: { projectId: string; documentVersionId: string }) {
    try {
      const versionId = encodeURIComponent(params.documentVersionId);
      const res = await ApsHttp.client.get(`data/v1/projects/${params.projectId}/versions/${versionId}`).json();
      const data = _parse('findDocumentVersionById', res);

      return Ok(data);
    } catch (error) {
      Logger.error({ error, msg: '[Files.findDocumentVersionById]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  // TODO: optimize batch fetch call so there's one call queue that pushes a `urn` to the request body.
  async findProcessingItemsState(params: { projectId: string; urns: string[] }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/documents:batch-get`, {
          body: JSON.stringify({ urns: params.urns }),
        })
        .json();

      const data = _parse('findItemProcessingStatus', res);

      return Ok(
        data.results.map(item => ({
          urn: item.urn,
          name: item.includedVersion.name,
          isProcessing: item.includedVersion.processingState !== 'PROCESSING_COMPLETE',
          parentFolderUrn: item.parentFolderUrn,
        }))
      );
    } catch (error) {
      Logger.error({ error, msg: '[Files.findManyItemsByIds]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async search(params: {
    projectId: string;
    folderUrns: string[];
    query?: string;
    fileTypes?: string[];
    sort?: { field: string; order: 'asc' | 'desc' }[];
    page?: number;
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/entities:search`, {
          body: JSON.stringify({
            // filters: params.fileTypes ? { fileType: { value: params.fileTypes } } : undefined,
            folderUrns: params.folderUrns,
            sort: params.sort,
            searchText: params.query ?? '',
            page: params.page ?? 1,
            recursive: true,
          }),
        })
        .json();

      const data = _parse('search', res);

      return Ok(data);
    } catch (error) {
      Logger.error({ error, msg: '[Files.search]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async listFileTypesForSearch(params: { projectId: string; folderUrns: string[]; recursive: boolean }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/search/categories:query`, {
          body: JSON.stringify({
            folderUrns: params.folderUrns,
            categoryKeys: ['fileType'],
            deleted: false,
            filterText: '',
            includeFolders: true,
            includeTopFolders: false,
            numberOfValues: 200,
            recursive: params.recursive,
          }),
        })
        .json();
      const data = _parse('fileTypes', res);

      return Ok(data);
    } catch (error) {
      Logger.error({ error, msg: '[Files.listFileTypesForSearch]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async listFilesFoldersUsingSearch(params: {
    projectId: string;
    folderUrns: string[];
    query?: string;
    fileTypes?: string[];
    sort?: { field: string; order: 'asc' | 'desc' }[];
    page?: number;
    filters: FileSearchFilters;
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const filters: {
        sourceIdType?: { value: {}[] };
        fileType?: { value: {}[] };
      } = {};

      if (params.filters.sourceIdType.length != 0) {
        filters.sourceIdType = { value: params.filters.sourceIdType };
      }

      if (params.filters.fileType.length != 0) {
        filters.fileType = { value: params.filters.fileType };
      }

      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/entities:search`, {
          body: JSON.stringify({
            folderUrns: params.folderUrns,
            sort: params.sort,
            searchText: params.query ?? '',
            page: params.page ?? 1,
            recursive: params.filters.recursive,
            filters,
            includeFolders: true,
            includePermission: true,
            includeTopFolders: params.filters.includeTopFolders,
            includeAttributes: false,
            includeContent: false,
          }),
        })
        .json();

      const data = _parse('search', res);
      let fileType: string = 'folders';

      const listOfFolders = data.folders.map(item => ({
        type: 'folders' as const,
        id: item.urn,
        name: item.name,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedByName,
        updatedById: item.updatedBy,
        supportedInLMV: true,
        supportedInVR: true,
        storageSize: item.storageSize,
        fileType: fileType,
        isProcessing: false,
        thumbnailId: '',
        latestVersionId: '',
      }));

      const listOfFiles = data.documents.map(item => {
        let urn = `urn:${btoa(item.urn)}`;
        urn = urn.replace('/', '_');
        fileType = FileUtils.getFileType({
          type: 'items',
          name: item.name,
          fileType: item.includedVersion.fileType,
        });

        return {
          type: 'items' as const,
          id: item.urn,
          name: item.name,
          updatedAt: item.updatedAt,
          updatedBy: item.updatedByName,
          updatedById: item.updatedBy,
          supportedInLMV: FileUtils.supportedInLMV(fileType),
          supportedInVR: FileUtils.supportedInVR(fileType),
          storageSize: item.includedVersion.storageSize,
          fileType: fileType,
          isProcessing: item.includedVersion.processingState !== 'PROCESSING_COMPLETE',
          thumbnailId: urn,
          latestVersionId: item.includedVersion.urn,
        };
      });

      const listOfFilesFolders = [...listOfFolders, ...listOfFiles];

      return Ok(listOfFilesFolders);
    } catch (error) {
      Logger.error({ error, msg: '[Files.listFilesFoldersUsingSearch]' });
      return Err({ code: 'UNKNOWN' });
    }
  },

  async deleteFiles(params: { projectId: string; fileIds: string[] }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/documents:delete`, {
          body: JSON.stringify(params.fileIds),
        })
        .json();
      const data = _parse('deleteFiles', res);

      Segment.track('Files Deleted', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.deleteFiles]');
    }
  },

  async createFolder(params: { projectId: string; attrs: { name: string; parentFolderUrn: string } }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/folders`, {
          body: JSON.stringify({
            ...params.attrs,
            inheritNamingStandards: true,
          }),
        })
        .json();
      const data = _parse('createFolder', res);

      Segment.track('Folder Created', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.createFolder]');
    }
  },

  async renameDocuments(params: { projectId: string; attrs: { name: string; versionedUrn: string } }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/documents:rename`, {
          body: JSON.stringify(params.attrs),
        })
        .json();
      const data = _parse('renameDocuments', res);

      Segment.track('Document Renamed', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.renameDocuments]');
    }
  },

  async renameFolder(params: { projectId: string; attrs: { name: string; urn: string } }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/folders:rename`, {
          body: JSON.stringify(params.attrs),
        })
        .json();
      const data = _parse('renameFolder', res);

      Segment.track('Folder Renamed', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.renameFolder]');
    }
  },

  async moveFolder(params: { projectId: string; attrs: { folderUrn: string; targetFolderUrn: string } }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/folders:move`, {
          body: JSON.stringify(params.attrs),
        })
        .json();
      const data = _parse('moveFolder', res);

      Segment.track('Folder Moved', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.moveFolder]');
    }
  },

  async deleteFolders(params: { projectId: string; folderIds: string[] }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await ApsHttp.dmV3Client
        .post(`dm/v3/projects/${projectId}/folders:batch-delete`, {
          body: JSON.stringify({ folders: params.folderIds }),
        })
        .json();
      const data = _parse('deleteFolders', res);

      Segment.track('Folder Deleted', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Files.deleteFolders]');
    }
  },
};

async function _createStorageLocation(params: { projectId: string; fileName: string; parentFolderId: string }) {
  Logger.info({ params, msg: '[Files] Creating storage location...' });

  try {
    const res = await ApsHttp.client
      .post(`data/v1/projects/${params.projectId}/storage`, {
        body: JSON.stringify({
          jsonapi: { version: '1.0' },
          data: {
            type: 'objects',
            attributes: {
              name: params.fileName,
            },
            relationships: {
              target: {
                data: {
                  type: 'folders',
                  id: params.parentFolderId,
                },
              },
            },
          },
        }),
      })
      .json();
    const data = _parse('_createStorageLocation', res);

    return Ok(data);
  } catch (error) {
    return HttpUtils.handleError(error, '[Files._createStorageLocation]');
  }
}

function _splitFile(file: File) {
  if (file.size < MULTIPART_THRESHOLD) {
    Logger.info({ file, msg: '[Files] Using single upload. File size under multipart threshold...' });
    return [file];
  }

  const parts = Math.ceil(file.size / CHUNK_SIZE);

  if (file.size < MULTIPART_THRESHOLD) {
    Logger.info({ file, parts, msg: '[Files] Splitting file for multipart upload...' });
    return [file];
  }

  return new Array(parts).fill(null).map((_, index) => {
    const start = index * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    return file.slice(start, end, file.type);
  });
}

async function _getSignedUploadUrls(params: { objectKey: string; parts: number; firstPart: number }) {
  Logger.info({ params, msg: '[Files] Getting signed upload urls...' });

  try {
    const searchParams = new URLSearchParams();
    searchParams.set('firstPart', String(params.firstPart));
    searchParams.set('parts', String(params.parts));
    searchParams.set('minutesExpiration', '30');

    const url = ApsHttp.path('acc', 'oss/v2', 'objects', params.objectKey, 'signeds3upload');
    const res = await ApsHttp.client.get(url, { searchParams }).json();
    const data = _parse('_getSignedUploadUrls', res);

    return Ok(data);
  } catch (error) {
    return HttpUtils.handleError(error, '[Files._getSignedUploadUrls]');
  }
}

async function _uploadChunk(params: {
  url: string;
  chunk: Blob;
  onProgress: (percentDecimal: number, xhr: XMLHttpRequest) => void;
}) {
  Logger.info({ url: params.url, msg: '[Files] Uploading chunk...' });

  try {
    const res = await HttpUtils.uploadWithProgress({
      url: params.url,
      file: params.chunk,
      onProgress: params.onProgress,
    });

    if (res.status !== 200) {
      return Err({ code: '[Files] Upload to s3 failed.' });
    }

    return Ok(true);
  } catch (error) {
    return HttpUtils.handleError(error, '[Files._uploadChunk]');
  }
}

async function _finalizeUpload(params: { bucketKey: string; objectKey: string; uploadKey: string }) {
  Logger.info({ params, msg: '[Files] Finalizing upload...' });

  try {
    const res = await ApsHttp.client
      .post(`oss/v2/buckets/${params.bucketKey}/objects/${params.objectKey}/signeds3upload`, {
        body: JSON.stringify({ uploadKey: params.uploadKey }),
      })
      .json();
    const data = _parse('_finalizeUpload', res);

    return Ok(data);
  } catch (error) {
    return HttpUtils.handleError(error, '[Files._finalizeUpload]');
  }
}

async function _createDocument(params: { projectId: string; parentFolderUrn: string; objectId: string; file: File }) {
  Logger.info({ params, msg: '[Files] Creating document version...' });

  const projectId = ProjectUtils.formatId(params.projectId);

  try {
    const res = await ApsHttp.dmV3Client
      .post(`dm/v3/projects/${projectId}/folders/${params.parentFolderUrn}/documents`, {
        body: JSON.stringify({
          file: {
            name: params.file.name,
            storageUrn: params.objectId,
          },
        }),
      })
      .json();

    const data = _parse('_createDocument', res);

    return Ok(data);
  } catch (error) {
    return HttpUtils.handleError(error, '[Files._createDocument]');
  }
}
