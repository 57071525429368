import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import {
  consoleLogoAlphaUrl,
  consoleLogoBetaUrl,
  consoleLogoDevUrl,
  consoleLogoDevStgUrl,
  consoleLogoProdUrl,
  fullLogoAlphaUrl,
  fullLogoBetaUrl,
  fullLogoDevUrl,
  fullLogoDevStgUrl,
  fullLogoProdUrl,
  webLogoAlphaUrl,
  webLogoBetaUrl,
  webLogoDevUrl,
  webLogoDevStgUrl,
  webLogoProdUrl,
} from '~/shared/utils/const.asset-urls';

export function useTemporaryBranding() {
  const $env = Oasis.Env.useStore();

  let currentEnv = 'Dev';
  if ($env.releaseChannel === 'alpha') currentEnv = 'Alpha';
  if ($env.releaseChannel === 'beta') currentEnv = 'Beta';
  if ($env.releaseChannel === 'devstg') currentEnv = 'DevSTG';

  useEffect(() => {
    document.title = $env.releaseChannel === 'prod' ? 'Autodesk Workshop XR' : `Autodesk Workshop XR ${currentEnv}`;
  }, [$env.releaseChannel, currentEnv]);

  switch ($env.releaseChannel) {
    case 'alpha':
      return {
        isProd: false,
        productName: 'Autodesk Workshop XR ' + currentEnv,
        shortProductName: 'Workshop XR ' + currentEnv,
        productIconUrl: consoleLogoAlphaUrl,
        logoUrl: fullLogoAlphaUrl,
        logomarkUrl: webLogoAlphaUrl,
      };

    case 'beta':
      return {
        isProd: false,
        productName: 'Autodesk Workshop XR ' + currentEnv,
        shortProductName: 'Workshop XR ' + currentEnv,
        productIconUrl: consoleLogoBetaUrl,
        logoUrl: fullLogoBetaUrl,
        logomarkUrl: webLogoBetaUrl,
      };

    case 'develop':
      return {
        isProd: false,
        productName: 'Autodesk Workshop XR ' + currentEnv,
        shortProductName: 'Workshop XR ' + currentEnv,
        productIconUrl: consoleLogoDevUrl,
        logoUrl: fullLogoDevUrl,
        logomarkUrl: webLogoDevUrl,
      };

    case 'devstg':
      return {
        isProd: false,
        productName: 'Autodesk Workshop XR ' + currentEnv,
        shortProductName: 'Workshop XR ' + currentEnv,
        productIconUrl: consoleLogoDevStgUrl,
        logoUrl: fullLogoDevStgUrl,
        logomarkUrl: webLogoDevStgUrl,
      };

    default:
      return {
        isProd: true,
        productName: 'Autodesk Workshop XR',
        shortProductName: 'Workshop XR',
        productIconUrl: consoleLogoProdUrl,
        logoUrl: fullLogoProdUrl,
        logomarkUrl: webLogoProdUrl,
      };
  }
}
