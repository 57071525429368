import { Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { OasisHttp } from '../../providers/http/oasis-http.provider';
import { ReleasesSchemas } from './releases.schemas';
import { ReleaseApp, ReleasePlatform } from './releases.types';

const _parse = HttpUtils.createScopedParser('Releases', ReleasesSchemas);

export const Releases = {
  async getReleaseMeta(params: {
    version: string;
    app: ReleaseApp;
    channel: string; // 'develop' | 'devstg' | 'alpha' | 'beta' | 'main'
    platform: ReleasePlatform;
  }) {
    try {
      const searchParams = new URLSearchParams('?version=' + params.version);
      searchParams.set('app', params.app);
      searchParams.set('platform', params.platform);
      searchParams.set('channel', params.channel);

      if (params.app === 'WorkshopXR_Viewer') {
        searchParams.set('allowMetadataComparison', 'true');
      }

      const releaseAPI = params.version === 'latest' ? 'v1/releases/latest' : 'v1/releases';
      const res = await OasisHttp.get(releaseAPI, { searchParams }).json();
      const data = _parse('getReleaseMeta', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Releases.getReleaseMeta]');
    }
  },
};

export type ReleasesService = typeof Releases;
